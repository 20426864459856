import React from 'react'

const AdminSearchUser = ({ data, click }) => {
    return (
        <div role="button" onClick={() => click(data.uid)} className="mb-2 mt-2 rounded border d-flex align-items-center" style={{ height: "80px" }}>
            <img className="rounded ms-2 me-2" width={64} height={64} src={(data.picture === null) ? "/lion.png" : data.picture} alt={data.name} />
            <div>
                <h5>{data.name}</h5>
                <p>{data.mobile} | {data.club_name}</p>
            </div>
        </div>
    )
}

export default AdminSearchUser