import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import Zones from '../../components/Zones'
import Clubs from '../../components/Clubs'

const PublicZone = () => {

    const { id } = useParams()
    const [zones, setZones] = useState([])
    const [clubs, setClubs] = useState([])
    const [html, setHtml] = useState('')

    useEffect(() => {
        ; (async () => {
            fetchRegion(id)
        })()
    }, [id])

    useEffect(() => {
        setHtml(
            clubs.map((club) => (
                <Clubs data={club} navigateUrl="/club/" />
            ))
        )
    }, [clubs])


    const fetchRegion = async (id) => {
        const response = await fetch("/v1/region/" + id, { method: "GET", headers: {'Content-Type': 'application/json', Authorization: `${localStorage.getItem('_memberAuth')}` } })
        const data = await response.json()
        setZones(data)
    }

    const viewClubs = async (zid) => {
        const response = await fetch("/v1/zone/" + zid, { method: "GET", headers: {'Content-Type': 'application/json', Authorization: `${localStorage.getItem('_memberAuth')}` } })
        const data = await response.json()
        setClubs(data)
    }



    const addClubDialog = (zid) => {
        // do nothing in public
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6}>
                        {zones.map((zone) => (
                            <Zones data={zone} user={true} viewHandler={viewClubs} addHandler={addClubDialog} />
                        ))}
                    </Col>

                    <Col lg={6}>
                        {html}
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PublicZone