import React, {useEffect} from 'react';
import {Button, Card, Form, InputGroup, ListGroup} from "react-bootstrap";
import {XLg} from "react-bootstrap-icons";
import {Link} from "react-router-dom";

const AdminCabinet = ({cabinet, handleDelete}) => {
    const [co, setCo] = React.useState([]);
    const [update, setUpdate] = React.useState({parent: '', order: ''});
    useEffect(()=>{
        setCo(cabinet.co);
        setUpdate({parent: '', order: cabinet.admin.cabinet_order});
    },[cabinet])


    const handleSubmit = async () => {
        const id = cabinet.admin.id;
        await fetch(`/v1/cabinet/${id}`, {
            method: 'PUT',
            headers: { 'Authorization': localStorage.getItem("_adminAuth"), 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(update)
        });
    }

    const handleCancel = (id) => {
        const newData = co.filter(user => user.uid !== id);
        setCo(newData);
        fetch(`/v1/cabinet/${id}`, {
            method: 'DELETE',
            headers: {'Authorization': localStorage.getItem("_adminAuth")}
        }).then(r =>{
            console.log(r);
        });
    }

    return (
        <Card className='mt-2 mb-2'>
            <Card.Header className="text-center">
                <Card.Title>
                    <Link to={`/admin/member/${cabinet.admin.uid}`} target='_blank' className='text-decoration-none'>{cabinet.admin.name}</Link>
                </Card.Title>
                <Card.Subtitle>
                    {cabinet.admin.role}
                </Card.Subtitle>
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    ID: {cabinet.admin.id}
                </Card.Text>
                <InputGroup className="mb-3">
                    <InputGroup.Text>ID</InputGroup.Text>
                    <Form.Control
                        placeholder="Parent ID"
                        aria-label="Parent ID"
                        value={update.parent}
                        onChange={e => setUpdate({...update, parent: e.currentTarget.value})}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>ORDER</InputGroup.Text>
                    <Form.Control
                        placeholder="PublicCabinetRoute Order"
                        aria-label="PublicCabinetRoute Order"
                        value={update.order}
                        onChange={e => setUpdate({...update, order: e.currentTarget.value})}
                    />
                </InputGroup>
                <ListGroup variant="flush">
                    {
                        co.map((user, index) => (
                            <ListGroup.Item key={index}>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        {index+1}. <Link to={`/admin/member/${user.uid}`} target='_blank' className='text-decoration-none'>{user.name}</Link><br/>{user.club_name}<br/>{user.role}
                                    </div>
                                    <div>
                                        <Button variant={"danger"} onClick={() => handleCancel(user.uid)}><XLg/></Button>
                                    </div>
                                </div>
                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-between">
                <Button variant='danger' onClick={()=>handleDelete(cabinet.admin.uid)}>Delete</Button>
                <Button variant='primary' onClick={handleSubmit}>Update</Button>
            </Card.Footer>
        </Card>
    );
};

export default AdminCabinet;