import { useEffect } from "react"
import { useState } from "react"
import { Badge, Button, Card, CardBody, CardFooter, CardImg } from "react-bootstrap"
import { Activity, Trash2Fill } from "react-bootstrap-icons"

const AdminBanner = ({ item, deleteHandler }) => {
    const [banner, setBanner] = useState({})
    useEffect(() => {
        if (item != null) {
            setBanner(item)
        }
    }, [item])


    return (
        <div className="col-lg-4 mb-2 mt-2">
            <Card>
                <CardImg src={banner.image} height="256px" width="auto" alt={banner.bid} />
                <CardBody >

                </CardBody>
                <CardFooter className='d-flex justify-content-between'>
                    <div><Badge variant={banner.status === "ACTIVE" ? "success" : "warning"}><Activity /> {banner.sequence}</Badge></div>
                    <Button variant="danger" onClick={() => deleteHandler(banner.bid)}><Trash2Fill /> Delete</Button>
                </CardFooter>
            </Card>
        </div>
    )
}


export default AdminBanner