
import React, { useEffect, useState } from 'react'
import {
    Card,
    CardTitle,
    CardHeader,
    CardBody,
    Row,
    Col,
    Form,
    CardFooter,
    Button,
    Container,
    Modal, Spinner, Alert
} from 'react-bootstrap'
import {PenFill, Trash2Fill} from 'react-bootstrap-icons'
import { useParams } from 'react-router-dom'
import DatePicker from "react-datepicker";

const AdminUserRoute = () => {
    const [data, setData] = useState({})
    const { id } = useParams()
    const [loading, setLoading] = useState({ showing: false, message: "No Message" })

    useEffect(() => {
        ; (async () => {
            const response = await fetch("/v1/member/" + id, { method: "GET", headers: {Authorization: localStorage.getItem("_adminAuth")} })
            const data = await response.json()
            setData(data)
        })()
    }, [id])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => {
            return {...prevData, [name]: value};
        });
    };

    const handleLoading = (showing, message) => {
        setLoading({
            showing: showing,
            message: message
        });
    };
    const handleClose = () => handleLoading(false, "");
    let setDate = (date) => {
        const newDate = convertDate(date);
        setData(prevData => {
            return {...prevData, "dob": newDate};
        });
    }
    const convertDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    let updateMemberData = async () => {
        handleLoading(true, <div className='d-flex justify-content-center align-items-center'>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>)
        const response = await fetch('/v1/member', {
            method: 'PUT',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("_adminAuth")},
            body: JSON.stringify({...data, "dob": new Date(data.dob).toISOString().slice(0, 19).replace('T', ' ')})
        })

        if (response.ok) {
            handleLoading(true,
                <Alert variant="success">
                    Member updated successfully !
                </Alert>
            )
        } else {
            handleLoading(true,
                <Alert variant="danger">
                    Member update failed !
                </Alert>
            )
        }
    };
    const deleteData = async () => {
        handleLoading(true, <div className='d-flex justify-content-center align-items-center'>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>)
        const response = await fetch(`/v1/member/${data.uid}`, { method: 'DELETE', headers: { Authorization: localStorage.getItem("_adminAuth") } })
        if (response.ok) {
            handleLoading(true,
                <Alert variant="success">
                    Member deleted successfully !
                </Alert>
            )
        } else {
            handleLoading(true,
                <Alert variant="danger">
                    Delete failed !
                </Alert>
            )
        }
    }


    return (
        <>
            <Container>
                <>
                    <Card className="mt-2 mb-2">
                        <CardHeader>
                            <CardTitle>
                                Member Information
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <div className="d-flex justify-content-center align-items-center">
                                <img className="rounded" src={(data.picture === null) ? "/lion.png" : data.picture} width={128} height={128} alt="data profile" />
                            </div>
                            <hr />
                            <Row>
                                <Col lg={6}>
                                    <Form>
                                        <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                                            <Form.Label>MEMBER ID</Form.Label>
                                            <Form.Control type="number" value={data.uid} disabled placeholder="Auto ID" />
                                        </Form.Group>
                                        <Form.Group className="mb-1" controlId="exampleForm.ControlInput3">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" value={data.name} name="name" onChange={handleChange} placeholder="Name" />
                                        </Form.Group>
                                        <Form.Group className="mb-1" controlId="exampleForm.ControlInput4">
                                            <Form.Label>Phone number</Form.Label>
                                            <Form.Control type="text" value={data.mobile} name="mobile" onChange={handleChange} placeholder="Phone number" />
                                        </Form.Group>
                                        <Form.Group className="mb-1" controlId="exampleForm.ControlInput5">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" value={data.email} name="email" onChange={handleChange} placeholder="Email Address" />
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col lg={6}>

                                    <Form>
                                        <Form.Group className="mb-1" controlId="exampleForm.ControlInput7">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control type="text" value={data.address} name="address" onChange={handleChange} placeholder="Designation" />
                                        </Form.Group>
                                        <Form.Group className="mb-1" controlId="exampleForm.ControlInput12">
                                            <Form.Label>Profession</Form.Label>
                                            <Form.Control type="text" value={data.profession} name="profession" onChange={handleChange} placeholder="Profession" />
                                        </Form.Group>
                                        <Form.Group className="mb-1" controlId="exampleForm.ControlInput8">
                                            <Form.Label>Blood group</Form.Label>
                                            <Form.Control type="text" value={data.blood} name="blood" onChange={handleChange} placeholder="Blood group" />
                                        </Form.Group>
                                        <Form.Group className="mb-1" controlId="exampleForm.ControlInput9">
                                            <Form.Label>Date of Birth</Form.Label>
                                            <br />
                                            <DatePicker selected={data.dob} name="dob" onChange={(date) => setDate(date)} />
                                        </Form.Group>

                                    </Form>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className='d-flex justify-content-between'>
                            <Button variant="primary" onClick={updateMemberData}> <PenFill /> Update</Button>
                            <Button variant="danger" onClick={deleteData}> <Trash2Fill /> Delete</Button>
                        </CardFooter>
                    </Card>

                    <Modal show={loading.showing} onHide={handleClose} backdrop="static" keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>MEMBER</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{loading.message}</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            </Container>
        </>
    )
}

export default AdminUserRoute