import React, { useEffect, useState } from 'react'
import { Spinner, Container, Button } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

const AdminLogin = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [status, setStatus] = useState(true)
    useEffect(() => {
        ; (async () => {
            if (token) {
                const verifier = await fetch('/v1/auth/admin', { method: 'POST', body: JSON.stringify({ token: token }), headers: { 'Content-Type': 'application/json' } })
                if (verifier.ok) {
                    const data = await verifier.json()
                    localStorage.setItem("_adminAuth", data.token)
                    navigate('/admin')
                } else {
                    setStatus(false)
                }
            } else {

            }
        })()

    }, [token, navigate])

    const login = () => {
        navigate('/admin')
    }

    return (
        <>
            {
                status ? <Container className='d-flex justify-content-center align-items-center'>
                    <Spinner animation="border" role="status" className='m-2'></Spinner>
                    <span>Please wait... We are checking...</span>
                </Container> :
                    <Container className='d-flex justify-content-center align-items-center h-100'>
                        <div>
                            <h1 className='text-center'>Access denied</h1><hr /><h2 className='text-center'>You are not authorized to access this content</h2>
                            <div className='d-flex justify-content-center align-items-center'>
                                <Button variant='primary' className='m-4' onClick={login}>Try again</Button>
                            </div>
                        </div>
                    </Container>
            }
        </>
    );
}

export default AdminLogin