import 'react-image-crop/dist/ReactCrop.css'
import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Card, Col, Form, Modal, Row} from "react-bootstrap";
import {Camera, Crop, Save} from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import {jwtDecode} from "jwt-decode";
import {useNavigate} from "react-router-dom";
import {centerCrop, ReactCrop, makeAspectCrop, convertToPixelCrop} from "react-image-crop";
import { v4 as uuid } from 'uuid';
import setCanvasPreview from "../../components/setCanvasPreview";


const PublicProfileRoute = () => {
    const inputRef = useRef(null);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [uploadingStatus, setUploadingStatus] = useState({variant: '', message: ''});
    const [data, setData] = useState({});
    let ac= localStorage.getItem("accountData")

    const [srcImg, setSrcImg] = useState(null);
    const [isUser, setIsUser] = useState(false);
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [error, setError] = useState("");
    const [crop, setCrop] = useState(null)
    const ASPECT_RATIO = 1;
    const MIN_DIMENSION = 156;


    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

        const crop = makeAspectCrop(
            {
                unit: "%",
                width: cropWidthInPercent,
            },
            ASPECT_RATIO,
            width,
            height
        );
        const centeredCrop = centerCrop(crop, width, height);
        setCrop(centeredCrop);
    };
    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        ;(async ()=> {
            const jwt = jwtDecode(localStorage.getItem("_memberAuth"));
            if (jwt.role === "user"){
                setIsUser(true)
                const response = await fetch("/v1/account", { method: "GET", headers: { 'authorization': localStorage.getItem("_memberAuth") } })
                if (response.ok) {
                    const json = await response.json()
                    setData(json)
                    localStorage.setItem("accountData", JSON.stringify(json))
                }
            }else{
                setIsUser(false)
            }
        })()
    },[ac])


    const onSelectFile = (e) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            const imageElement = new Image();
            const imageUrl = reader.result?.toString() || "";
            imageElement.src = imageUrl;

            imageElement.addEventListener("load", (e) => {
                if (error) setError("");
                const { naturalWidth, naturalHeight } = e.currentTarget;
                if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
                    setError("Image must be at least 150 x 150 pixels.");
                    return setSrcImg("");
                }
            });
            setSrcImg(imageUrl);
        });
        reader.readAsDataURL(file);
        handleShow()
    };

    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => {
            return {...prevData, [name]: value};
        });
    };

    let setDate = (date) => {
        const newDate = convertDate(date);
        setData(prevData => {
            return {...prevData, "dob": newDate};
        });
    }
    const convertDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    let updateMemberData = async () => {
        setUploadingStatus({variant: 'info', message: `Updating...`});
        const call = await fetch('/v1/member', {
            method: 'PUT',
            headers: {'Content-Type': 'application/json', Authorization: localStorage.getItem("_memberAuth")},
            body: JSON.stringify({...data, "dob": new Date(data.dob).toISOString().slice(0, 19).replace('T', ' ')})
        })

        if (call.ok){
            setUploadingStatus({variant: 'success', message: `Updated !`});
            localStorage.setItem("accountData", JSON.stringify(data));
        }else {
            setUploadingStatus({variant: 'danger', message: call.statusText});
        }
    };

    const uploadImage = async (file) => {
        setUploadingStatus({variant: 'primary', message: `Resizing image...`});
        const response1 = await fetch(file);
        const blob = await response1.blob();

        resizeImage(blob, async function (resizedImageURL) {

            const response2 = await fetch(resizedImageURL);
            const blob2 = await response2.blob();

            setUploadingStatus({variant: 'info', message: `Uploading image...`});
            let formData = new FormData();
            formData.append('file', blob2, `${uuid()}.jpg`);
            localStorage.removeItem("accountData");

            let response = await fetch("/v1/member/upload", {
                    method: 'PUT',
                    body: formData,
                    headers: {
                        Authorization: localStorage.getItem("_memberAuth")}
                }
            )
            if (response.ok) {
                let d = await response.json();
                setData({...data, picture: d.file});
                localStorage.setItem("accountData", JSON.stringify({...data, picture: d.file}));
                setUploadingStatus({variant: 'success', message: `Updated !`});
            }else{
                setUploadingStatus({variant: 'danger', message: response.statusText});
            }
        });
    }


    function resizeImage(file, callback) {
        const img = new Image();
        const reader = new FileReader();

        reader.onload = function (e) {
            img.src = e.target.result;
        };

        img.onload = function () {
            // Create a canvas element
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set the canvas dimensions to the new size
            canvas.width = 256;
            canvas.height = 256;

            // Draw the image on the canvas with the new dimensions
            ctx.drawImage(img, 0, 0, 256, 256);

            // Get the data URL of the resized image
            const resizedImageURL = canvas.toDataURL('image/png');

            // Execute the callback with the data URL
            if (callback) {
                callback(resizedImageURL);
            }
        };

        reader.readAsDataURL(file);
    }


    const logout = ()=> {
        localStorage.removeItem("_memberAuth");
        navigate('/login')
    }

    return (
        <div>
            {
                isUser ? <Card className={'mt-2 mb-2'}>
                    <Card.Header>
                        <Card.Title>
                            {data.name}
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div className={'d-flex justify-content-center align-items-center'}>
                            <img
                                src={data.picture ? data.picture : '/lion.png'}
                                alt='avatar'
                                className='rounded bg-dark'
                                width={128}
                                height={128}
                            />
                            <input className='d-none' ref={inputRef} type="file" accept="image/*" multiple={false}
                                   onChange={onSelectFile}/>
                        </div>
                        <div className={'d-flex justify-content-center align-items-center'}>
                            <Button className={'m-2'} onClick={() => inputRef.current.click()}><Camera/> Select</Button>
                        </div>
                        <hr/>
                        <Row>
                            <Col lg={6}>

                                <label><b>Member ID</b></label>
                                <p>{data.uid}</p>

                                <label><b>Name</b></label>
                                <p>{data.name}</p>

                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput4">
                                    <Form.Label>Phone number</Form.Label>
                                    <Form.Control type="text" value={data.mobile} name="mobile" onChange={handleChange} placeholder="Phone number" />
                                </Form.Group>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput5">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" value={data.email} name="email" onChange={handleChange} placeholder="Email Address" />
                                </Form.Group>

                                <label><b>Club Name</b></label>
                                <p>{data.club_name}</p>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput7">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" value={data.address} name="address" onChange={handleChange} placeholder="Designation" />
                                </Form.Group>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput12">
                                    <Form.Label>Profession</Form.Label>
                                    <Form.Control type="text" value={data.profession} name="profession" onChange={handleChange} placeholder="Profession" />
                                </Form.Group>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput8">
                                    <Form.Label>Blood group</Form.Label>
                                    <Form.Control type="text" value={data.blood} name="blood" onChange={handleChange} placeholder="Blood group" />
                                </Form.Group>
                                <Form.Group className="mb-1" controlId="exampleForm.ControlInput9">
                                    <Form.Label>Date of Birth</Form.Label>
                                    <br />
                                    <DatePicker selected={data.dob} name="dob" onChange={(date) => setDate(date)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className={'d-flex justify-content-between'}>
                        <Button variant={'danger'} onClick={logout}>Logout</Button>
                        <Button variant={'primary'} onClick={updateMemberData}><Save/> Save</Button>
                    </Card.Footer>
                </Card>
                    :
                    <Card className={'mt-2'}>
                        <Card.Header>
                            <Card.Title>Guest Mode</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>Profile not available in Guest mode</Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant={'danger'} onClick={logout}>Logout</Button>
                        </Card.Footer>
                    </Card>
            }


            <Modal show={(uploadingStatus.variant !== '')} onHide={()=> setUploadingStatus({...uploadingStatus, variant: ''})}>
                <Modal.Header closeButton>
                    <Modal.Title>Update status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <Alert variant={uploadingStatus.variant}>
                            {uploadingStatus.message}
                        </Alert>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'primary'} onClick={() => setUploadingStatus({...uploadingStatus, variant: ''})}>OK</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={show} onHide={handleClose} fullscreen = {true}>
                <Modal.Header closeButton>
                    <Modal.Title>Crop Profile Picture</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'d-flex justify-content-center align-items-center'}>
                    {srcImg && (
                        <ReactCrop
                            crop={crop}
                            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
                            keepSelection
                            aspect={ASPECT_RATIO}
                            minWidth={MIN_DIMENSION}>
                            <img
                                ref={imgRef}
                                style={{maxWidth: '500px'}}
                                src={srcImg}
                                alt="Upload"
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}
                    {crop && (
                        <canvas
                            ref={previewCanvasRef}
                            className="mt-4 d-none"
                            style={{
                                border: "1px solid black",
                                objectFit: "contain",
                                width: 156,
                                height: 156,
                            }}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer className={'d-flex justify-content-between'}>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={async () => {
                        setCanvasPreview(
                            imgRef.current,
                            previewCanvasRef.current,
                            convertToPixelCrop(
                                crop,
                                imgRef.current.width,
                                imgRef.current.height
                            )
                        );
                        const dataUrl = previewCanvasRef.current.toDataURL();
                        uploadImage(dataUrl)
                        handleClose();
                    }}><Crop/> Crop</Button>
                </Modal.Footer>
            </Modal>
        </div>
);
};

export default PublicProfileRoute;