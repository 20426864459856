import { Badge, Button, Card, CardBody, CardFooter, CardHeader, CardTitle } from "react-bootstrap"
import { Trash2Fill } from "react-bootstrap-icons"


const Message = ({ message, deleteHandler }) => {
    return (
        <Card className="mt-2 mb-2">
            <CardHeader>
                <CardTitle>
                    <Badge variant="primary">#{message.sequence}</Badge> {message.label}
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Card.Text>
                    {message.body_english}
                </Card.Text>
                <hr />
                <Card.Text>
                    {message.body_hindi}
                </Card.Text>
            </CardBody>
            <CardFooter>
                <Button variant="danger" onClick={() => deleteHandler(message.mid)}><Trash2Fill /> Delete</Button>
            </CardFooter>
        </Card>
    )
}

export default Message