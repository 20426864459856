import React from 'react'
import { Button } from 'react-bootstrap'

const AdminSearchClub = ({ data, zone }) => {

    const add = async () => {
        await fetch("/v1/clubs", { method: "PUT", headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem("_adminAuth") }, body: JSON.stringify({ cid: data.cid, zone: zone }) })

        alert("Club added successfully...")
    }

    return (
        <Button onClick={add} className='m-2'>{data.club_name}</Button>
    )
}

export default AdminSearchClub