import React, { useState } from 'react'
import { Card, CardHeader, CardTitle, CardBody, Form, CardFooter, Button, Modal, Spinner } from 'react-bootstrap'
import { PeopleFill } from 'react-bootstrap-icons';
import AdminSearchUser from './admin/AdminSearchUser';

const AddZone = ({ submit }) => {
    const [currentUid, setCurrentUid] = useState(0)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [progress, setProgress] = useState(false)
    const [searched, setSearched] = useState([])

    const searchUser = async () => {
        setProgress(true)
        const q = document.getElementById("sInput").value
        const response = await fetch(`/v1/member?q=${q}`, { method: "GET", headers: { Authorization: localStorage.getItem("_adminAuth") } })
        setProgress(false)
        const data = await response.json()
        setSearched(data)
    }

    const clickHandler = (id) => {
        setCurrentUid(id)
        handleClose()
    }

    const handleSubmit = () => {
        const zone = document.getElementById("zonename").value
        submit(zone, currentUid)
    }

    return (
        <>
            <Card className='mt-2 mb-2'>
                <CardHeader>
                    <CardTitle>
                        Add zone
                    </CardTitle>
                </CardHeader>

                <CardBody>
                    <Form.Group className="mb-1">
                        <Form.Label>Zone Name</Form.Label>
                        <Form.Control type="text" id="zonename" placeholder="Zone name" />
                        <Form.Text className="text-muted">
                            Zone name required !
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-1">
                        <Form.Label>Member ID</Form.Label>
                        <div className='d-flex justify-content-center align-items-center'>
                            <Form.Control type="text" disabled id="member" placeholder="Member ID" value={currentUid} className='rounded-0' />
                            <Button variant='primary' onClick={handleShow} className='rounded-0'><PeopleFill /></Button>
                        </div>
                    </Form.Group>

                </CardBody>
                <CardFooter>
                    <Button variant="success" onClick={handleSubmit}>Add</Button>
                </CardFooter>
            </Card>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>SEARCH MEMBER</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="d-flex">
                        <Form.Control type="text" id='sInput' placeholder="Search by name or Phone" />
                        <Button className="ms-2" onClick={searchUser}>Search</Button>
                    </Form>
                    <hr />
                    {
                        searched.map((user) => (
                            <AdminSearchUser data={user} click={clickHandler} />
                        ))
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        progress ? <div className='d-flex justify-content-center align-items-center'>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div> : ""
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddZone