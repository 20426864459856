import React, {useEffect} from 'react';
import {Button, Spinner} from "react-bootstrap";
import {useNavigate, useSearchParams} from "react-router-dom";

const Auth = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    useEffect(() => {
        ; (async () => {
            if (token) {
                const verifier = await fetch('/v1/auth/guest', { method: 'POST', body: JSON.stringify({ token: token }), headers: { 'Content-Type': 'application/json' } })
                if (verifier.ok) {
                    const data = await verifier.json()
                    localStorage.setItem("_memberAuth", data.token)
                    navigate('/')
                }
            } else {

            }
        })()

    }, [token, navigate])


    return (
        <div className={'d-flex justify-content-center align-items-center'}>
            <Button variant="primary" disabled className={'mt-4'}>
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                Authorizing...
            </Button>
        </div>
    );
};

export default Auth;