import React, { useEffect, useState } from 'react'
import { Col, Container, InputGroup, Row, Form, Button, Alert } from 'react-bootstrap'
import { Plus, Upload } from 'react-bootstrap-icons';
import AdminBanner from '../../components/admin/AdminBanner';


const AdminBannerRoute = () => {
    document.title = "Banners"
    const [loading, setLoading] = useState({ variant: 'primary', message: 'Welcome' })
    const [banners, setBanners] = useState([])
    const [sequence, setSequence] = useState(0)
    const [selectedFile, setSelectedFile] = useState(null);

    const fetchBanners = async () => {
        setLoading({ variant: "info", message: "Fetching banners..." })
        const response = await fetch("/v1/banners", { method: 'GET', headers: { 'Authorization': localStorage.getItem("_adminAuth") } });
        if (!response.ok) {
            setLoading({ variant: "danger", message: response.statusText })
        } else {
            const data = await response.json();
            setLoading({ variant: "success", message: "Banners fetched successfully..." })
            setBanners(data)
        }
    };


    useEffect(() => {
        ; (async () => {
            await fetchBanners()
        })()
    }, [])


    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const uploadImage = async () => {
        if (selectedFile) {
            setLoading({ variant: "info", message: "Uploading..." })
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('sequence', document.getElementById("sequence").value)

            const response = await fetch("/v1/banners", { method: 'POST', body: formData, headers: { Authorization: localStorage.getItem("_adminAuth") } })
            if (!response.ok) {
                setLoading({ variant: "danger", message: response.statusText })
            } else {
                setLoading({ variant: "success", message: "AdminBanner added successfully..." })
                await fetchBanners()
                setSelectedFile(null);
            }

        }
    }

    const deleteBanner = async (id) => {
        setLoading({ variant: "warning", message: "Removing..." })
        const res = await fetch(`/v1/banners/${id}`, { method: 'DELETE', headers: { 'Authorization': localStorage.getItem("_adminAuth") } })
        if (res.ok) {
            const newData = banners.filter(banner => banner.bid !== id);
            setBanners(newData);
            setLoading({ variant: "success", message: "AdminBanner Removed" })
        } else {
            setLoading({ variant: "danger", message: "Failed..." })
        }
    }

    return (
        <>
            <div><Alert variant={loading.variant} className='rounded-0'><Alert.Heading>{loading.message}</Alert.Heading></Alert></div>
            <Container>
                <Row>
                    <Col lg={10} className="order-lg-1 order-2">
                        <Container>
                            <Row>
                                {banners.map((item) => (
                                    <AdminBanner key={item.bid} item={item} deleteHandler={deleteBanner} />
                                ))}
                            </Row>
                        </Container>
                    </Col>

                    <Col lg={2} className="order-lg-2 order-1">
                        <div className=" mt-2 mb-2">
                            <Container fluid>
                                <div className="d-flex justify-content-center align-items-center position-relative">
                                    <img
                                        src={selectedFile ? URL.createObjectURL(selectedFile) : '/lion.png'}
                                        alt='avatar'
                                        className='rounded bg-dark border border-primary w-100'
                                        style={{ filter: 'brightness(0.7)', height: 'auto' }}
                                    />
                                    <Plus size={48} role='button' onClick={() => document.getElementById('upload').click()} className='position-absolute bg-primary rounded-circle' style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }} />
                                </div>

                                <input className='d-none' id="upload" type="file" accept="image/*" multiple={false} onChange={handleFileChange} />


                                <InputGroup className="mb-3 mt-3">
                                    <InputGroup.Text id="basic-addon3">
                                        Sequence
                                    </InputGroup.Text>
                                    <Form.Control id='sequence' value={sequence} onChange={(e) => setSequence(e.target.value)} aria-describedby="basic-addon3" />
                                </InputGroup>

                                <Button variant="primary" className="w-100 mt-2" onClick={uploadImage}><Upload /> Upload</Button>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AdminBannerRoute