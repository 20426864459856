import {Card} from "react-bootstrap";


const Empty = () => {
    return(
        <>
        <Card className="m-2">
            <Card.Header>
                <Card.Title>Empty</Card.Title>
            </Card.Header>
            <Card.Body>
                <Card.Text>No data found</Card.Text>
            </Card.Body>
        </Card>
        </>
    )
}

export default Empty;