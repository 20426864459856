import React from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Card, CardBody, CardFooter, CardHeader, CardSubtitle, CardTitle} from "react-bootstrap";
import {PeopleFill, PersonFillGear} from "react-bootstrap-icons";

const AdminRegion = ({ data, editHandler}) => {
    const navigate = useNavigate()
    const viewRegion = (rid) => {
        navigate('/admin/zone/' + rid)
    }

    return (
        <Card className='mt-2 mb-2'>
            <CardHeader>
                <CardTitle>{data.label}</CardTitle>
                <CardSubtitle><b>{data.name}</b></CardSubtitle>
            </CardHeader>
            <CardBody>
                <CardSubtitle>{data.club_name}</CardSubtitle>
                <Card.Text>{data.mobile || "No number"}</Card.Text>
            </CardBody>
            <CardFooter className='d-flex justify-content-between'>
                <Button variant='success' onClick={()=> editHandler(data)} className='me-2'><PersonFillGear /></Button>
                <Button variant='primary' onClick={() => viewRegion(data.rid)} className='me-2'><PeopleFill /> View ZC</Button>
            </CardFooter>
        </Card>
    )
};

export default AdminRegion;