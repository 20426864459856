import React from 'react';
import {Badge, Button, Card, CardFooter} from "react-bootstrap";
import {Activity} from "react-bootstrap-icons";

const AdminList = ({data, deleteHandler}) => {
    return (
        <div>
            <Card className={'mt-2 mb-2'}>
                <Card.Header>
                    <Card.Title><Badge><Activity/> {data.sequence} </Badge> {data.heading}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text>
                        {data.body}
                    </Card.Text>
                    <Card.Text>
                        {data.info}
                    </Card.Text>
                </Card.Body>
                <CardFooter>
                    <Button variant={'danger'} onClick={()=>deleteHandler(data.id)}>Delete</Button>
                </CardFooter>
            </Card>
        </div>
    );
};

export default AdminList;