import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

const PublicPageRoute = () => {

    const { id } = useParams()
    const [page, setPage] = useState({})
    useEffect(() => {
        ; (async () => {
            const response = await fetch(`/v1/page/${id}`, { method: "GET", headers: { 'authorization': localStorage.getItem("_memberAuth") } })
            if (response.ok) {
                const json = await response.json()
                setPage(json)
            } else {
                alert("Network issue")
            }
        })()
    }, [id])

    return (
        <Card className='m-2'>
            <CardHeader>
                <CardTitle className='text-center'>{page.title}</CardTitle>
            </CardHeader>
            <Card.Img variant="top" className='rounded-0' src={page.image} />
            <CardBody>
                {page.body}
            </CardBody>
        </Card>
    )
}

export default PublicPageRoute