import React from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, CardSubtitle, CardTitle } from 'react-bootstrap'
import { PeopleFill } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'

const Region = ({ data, navigateUrl }) => {
    const navigate = useNavigate()
    const viewRegion = (rid) => {
        navigate(navigateUrl + rid)
    }

    return (
        <Card className='mt-2 mb-2'>
            <CardHeader>
                <CardTitle>{data.label}</CardTitle>
                <CardSubtitle><b>{data.name}</b></CardSubtitle>
            </CardHeader>
            <CardBody>
                <CardSubtitle>{data.club_name}</CardSubtitle>
                <Card.Text>{data.mobile || "No number"}</Card.Text>
            </CardBody>
            <CardFooter>
                <Button variant='primary' onClick={() => viewRegion(data.rid)} className='me-2'><PeopleFill /> View ZC</Button>
            </CardFooter>
        </Card>
    )
}

export default Region