import React, { useEffect } from 'react'
import {Form, InputGroup, Spinner} from 'react-bootstrap'
import PublicCabinet from "../../components/user/PublicCabinet";
import {Search} from "react-bootstrap-icons";

const PublicCabinetRoute = () => {

    const [cabinets, setCabinets] = React.useState([]);
    const [bkpCabinets, setBkpCabinets] = React.useState([]);
    const [loading, setLoading] = React.useState(true);


    const find = (data) => {
        const filteredCabinets = bkpCabinets.filter(cabinet => {
            const matchesAdmin = cabinet.admin?.role?.toLowerCase().includes(data.toLowerCase()) ||
                cabinet.admin?.name?.toLowerCase().includes(data.toLowerCase());

            const matchesCo = cabinet.co.some(coMember =>
                coMember?.role?.toLowerCase().includes(data.toLowerCase()) ||
                coMember?.name?.toLowerCase().includes(data.toLowerCase())
            );

            return matchesAdmin || matchesCo;
        });
        setCabinets(filteredCabinets);
    }

    useEffect(() => {
        ; (async () => {
            const response = await fetch(`/v1/cabinet`, { method: "GET", headers: { 'authorization': localStorage.getItem("_memberAuth") } })
            if (response.ok) {
                const json = await response.json()
                // setPageSize(json.pages)
                setBkpCabinets(json)
                setCabinets(json)
            } else {
                alert("Network issue")
            }
            setLoading(false)
        })()
    }, [])
    
    return (
        <>
            <Form className="mt-2">
                <InputGroup>
                    <InputGroup.Text id="basic-addon1"><Search/></InputGroup.Text>
                    <Form.Control
                        placeholder="Search name, role"
                        aria-label="role"
                        onChange={(e) => find(e.target.value)}
                        aria-describedby="basic-addon1"
                    />
                </InputGroup>
            </Form>

            {
                loading ?
                    <div className={'d-flex justify-content-center align-items-center'}>
                        <Spinner animation="border" role="status" className={'m-4'}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                    : <></>
            }

            {
                cabinets.map((cabinet, index) => (
                    <PublicCabinet key={index} data={cabinet}/>
                ))
            }
        </>
    )
}

export default PublicCabinetRoute