import React from 'react'
import {Card, ListGroup} from 'react-bootstrap';
import {Link} from "react-router-dom";

const PublicCabinet = ({ data}) => {
    const [co, setCo] = React.useState([]);

    React.useEffect(() => {
        setCo(data.co)
    },[data])

    return (
        <Card className={'mt-2 mb-2'}>
            <Card.Header>
                <Card.Title><Link to={`/user/${data.admin.uid}`} target={'_blank'} className={'text-decoration-none'}>{data.admin.name}</Link></Card.Title>
                <Card.Subtitle>{data.admin.role} | {data.admin.club_name}</Card.Subtitle>
            </Card.Header>
            <Card.Body>
                <ListGroup variant="flush">
                    {
                        co.map((user, index) => (
                            <ListGroup.Item key={index}>
                                {index+1}. <Link to={`/user/${user.uid}`} target='_blank' className='text-decoration-none'>{user.name}</Link><br/>{user.club_name}<br/>{user.role}
                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>
            </Card.Body>
        </Card>
    )
}

export default PublicCabinet