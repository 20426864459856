import React from 'react';
import {Alert, Button, Col, Container, Form, InputGroup, Modal, Navbar, NavbarBrand, Row} from "react-bootstrap";
import AdminCabinet from "../../components/admin/AdminCabinet";
import {PlusLg} from "react-bootstrap-icons";

const AdminCabinetRoute = () => {
    const [cabinets, setCabinets] = React.useState([]);
    const [bkpCabinets, setBkpCabinets] = React.useState([]);
    const [status, setStatus] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [newCabinet, setNewCabinet] = React.useState({role: '', id: ''});
    const [progress, setProgress] = React.useState({variant: '', message: ''});
    const handleClose = () => {
        setShow(false);
        setProgress({variant: '', message: ''});
    };
    const handleShow = () => setShow(true);



    React.useEffect(() => {
        ;(async ()=> {
            setStatus('Fetching cabinet data...');
            const response = await fetch('/v1/cabinet', {method: 'GET', headers: {Authorization: localStorage.getItem('_adminAuth')}});
            if (response.ok) {
                const data = await response.json();
                setCabinets(data);
                setBkpCabinets(data)
                setStatus('Data fetched successfully...!');
            }else{
                setStatus(response.statusText)
            }
        })()
    },[])

    const find = (data) => {

        const filteredCabinets = bkpCabinets.filter(cabinet => {
            const matchesAdmin = cabinet.admin?.role?.toLowerCase().includes(data.toLowerCase()) ||
                cabinet.admin?.name?.toLowerCase().includes(data.toLowerCase());

            const matchesCo = cabinet.co.some(coMember =>
                coMember?.role?.toLowerCase().includes(data.toLowerCase()) ||
                coMember?.name?.toLowerCase().includes(data.toLowerCase())
            );

            return matchesAdmin || matchesCo;
        });

        setCabinets(filteredCabinets);
    }

    const handleDelete = (uid) => {
        const filter = bkpCabinets.filter(user => user.admin.uid !== uid);
        setCabinets(filter);
        setBkpCabinets(filter);
        fetch(`/v1/cabinet/${uid}`, {
            method: 'DELETE',
            headers: {'Authorization': localStorage.getItem("_adminAuth")}
        }).then(r =>{
            console.log(r);
        });
    }

    const addCabinet = async ()=> {
        setProgress({variant: 'info', message: 'Adding cabinet...'});

        let response = await fetch(`/v1/cabinet`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem("_adminAuth")},
            body: JSON.stringify(newCabinet)
        })

        if (response.ok) {
            const data = await response.json();
            if (data.code === 200) {
                setProgress({variant: 'success', message: 'Cabinet added !'});
            }else{
                setProgress({variant: 'warning', message: data.message});
            }
        }else{
            setProgress({variant: 'danger', message: response.statusText});
        }

    }

    return (
        <div>
            <Navbar expand="lg" bg="dark" className='border-bottom sticky-top' data-bs-theme="dark">
                <Container fluid>
                    <Form className="d-flex">
                        <Form.Control type="text" placeholder="Find by role or name" onChange={(e) => find(e.target.value)}/>
                    </Form>
                    <NavbarBrand>
                        {status}
                    </NavbarBrand>
                    <Button onClick={handleShow}><PlusLg/> Add Cabinet</Button>
                </Container>
            </Navbar>
            <Container fluid>
                <Row>
                    {
                        cabinets.map((cabinet, index) => (
                            <Col lg={3} key={index}>
                                <AdminCabinet cabinet={cabinet} key={index} handleDelete={handleDelete}/>
                            </Col>
                        ))
                    }
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Cabinet Manager</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>ROLE</InputGroup.Text>
                        <Form.Control
                            name='role'
                            value={newCabinet.role}
                            onChange={e => setNewCabinet({...newCabinet, role: e.currentTarget.value})}
                            placeholder="Cabinet role"
                            aria-label="PublicCabinetRoute role"
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Member ID</InputGroup.Text>
                        <Form.Control
                            name='id'
                            value={newCabinet.id}
                            onChange={e => setNewCabinet({...newCabinet, id: e.currentTarget.value})}
                            placeholder="Member ID"
                            aria-label="Member ID"
                        />
                    </InputGroup>

                    {
                        progress.variant !== '' ?
                            <Alert variant={progress.variant}>
                                {progress.message}
                            </Alert>
                            : <></>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={addCabinet}><PlusLg/> Add</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AdminCabinetRoute;