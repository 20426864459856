import {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Container,
    Form,
    InputGroup,
    Row
} from "react-bootstrap";
import {ChatSquareQuoteFill} from "react-bootstrap-icons";
import Message from "../../components/Message";


const AdminMessageRoute = () => {

    const [loading, setLoading] = useState({})
    const [messages, setMessages] = useState([]);
    const [add, setAddMessage] = useState({ heading: "", english: "", hindi: "", sequence: "" })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddMessage(prevData => {
            return {...prevData, [name]: value};
        });
    };

    useEffect(() => {
        ; (async () => {
            await fetchMessages()
        })()
    }, [])

    document.title = "Messages"


    const fetchMessages = async () => {
        setLoading({ variant: "info", message: "Fetching messages..." })

        const response = await fetch("/v1/messages", { method: 'GET', headers: { 'Authorization': localStorage.getItem("_adminAuth") } });

        if (response.ok) {
            const data = await response.json();
            setMessages(data);
            setLoading({ variant: "success", message: "Messages fetched successfully..." })
        } else {
            setLoading({ variant: "danger", message: response.statusText })
        }
    }

    const deleteMessage = async (id) => {
        setLoading({ variant: "warning", message: "Deleting data..." })
        const res = await fetch(`/v1/messages/${id}`, { method: 'DELETE', headers: { 'Authorization': localStorage.getItem("_adminAuth") } });
        if (res.ok) {
            const newMessages = messages.filter(message => message.mid !== id);
            setMessages(newMessages);
            setLoading({ variant: "success", message: "Message Removed" })
        } else {
            setLoading({ variant: "danger", message: res.statusText })
        }
    }

    const addMessage = async () => {
        setLoading({ variant: "info", message: "Adding messages..." })

        const response = await fetch('/v1/messages', { method: 'POST', headers: { 'Authorization': localStorage.getItem('_adminAuth'), 'Content-Type': 'application/json' }, body: JSON.stringify(add) });

        if (response.ok) {
            setLoading({ variant: "success", message: "Message Added..." })
        } else {
            setLoading({ variant: "danger", message: response.statusText })
        }
    }

    return (
        <>
            <div><Alert variant={loading.variant} className='rounded-0'><Alert.Heading>{loading.message}</Alert.Heading></Alert></div>

            <Container fluid>
                <Row>
                    <Col lg={8} className="order-lg-1 order-2">
                        {messages.map((item) => (
                            <Message key={item.mid} message={item} deleteHandler={deleteMessage} />
                        ))}
                    </Col>
                    <Col lg={4} className="order-lg-2 order-1">
                        <Card className="mt-2 mb-2">
                            <CardHeader>
                                <CardTitle>
                                    <ChatSquareQuoteFill size={24} /> Add new message
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Heading</Form.Label>
                                        <Form.Control name="heading" onChange={handleChange} value={add.heading} type="text" placeholder="Heading..." />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>English</Form.Label>
                                        <Form.Control name="english" onChange={handleChange} value={add.english} as="textarea" rows={3} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Hindi</Form.Label>
                                        <Form.Control name="hindi" onChange={handleChange} value={add.hindi} as="textarea" rows={3} />
                                    </Form.Group>

                                    <InputGroup className="mb-3 mt-3">
                                        <InputGroup.Text id="basic-addon3">
                                            Sequence
                                        </InputGroup.Text>
                                        <Form.Control name='sequence' onChange={handleChange} value={add.sequence} aria-describedby="basic-addon3" />
                                    </InputGroup>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Button variant="primary" onClick={addMessage}>Submit</Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AdminMessageRoute