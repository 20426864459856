import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {Col, Container, Form, InputGroup, Navbar, NavbarBrand, Row} from 'react-bootstrap'
import User from '../../components/User'
import PublicOfficer from '../../components/user/PublicOfficer'
import {BuildingFill, Search} from "react-bootstrap-icons";


const PublicClub = () => {


    const { id } = useParams()
    const [users, setUsers] = useState([])
    const [officers, setOfficers] = useState([])
    const [search, setSearch] = React.useState('');
    const [usersList, setUsersList] = React.useState([])
    const [officersList, setOfficersList] = React.useState([])
    const [club, setClub] = React.useState({})

    useEffect(() => {
        ; (async () => {
            const response = await fetch("/v1/clubs/" + id, { method: "GET", headers: {Authorization: localStorage.getItem("_memberAuth")} })
            const data = await response.json()
            setUsers(data.members)
            setUsersList(data.members)
            setOfficersList(data.officers)
            setOfficers(data.officers)
            setClub(data.club)
        })()
    }, [id])

    useEffect(() => {
        let filteredUsers = usersList.filter((user) =>
            user.name?.toLowerCase().includes(search.toLowerCase())
        )
        setUsers(filteredUsers);
        let filteredOfficers = officersList.filter((user) =>
            user.name?.toLowerCase().includes(search.toLowerCase())
        )
        setOfficers(filteredOfficers);
    }, [officersList, search, usersList]);

    return (
        <>
            <Navbar expand="lg" bg="dark" className='border-bottom' data-bs-theme="dark">
                <Container fluid>
                    <NavbarBrand>
                        <BuildingFill/> {club.club_name}
                    </NavbarBrand>
                </Container>
            </Navbar>
            <Container fluid>
                <Form className="mt-2">
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1"><Search/></InputGroup.Text>
                        <Form.Control
                            placeholder="Member name"
                            aria-label="membername"
                            value={search}
                            onChange={e=> {setSearch(e.target.value)}}
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </Form>
                <Row>
                    <Col lg={6}>
                        <h4 className='badge bg-primary mt-3'>Members</h4>

                        {
                            users.map((user) => (
                                <User key={user.uid} data={user} navigateUrl="/user/" />
                            ))
                        }
                    </Col>
                    <Col lg={6}>
                        <h4 className='badge bg-success mt-3'>Officers</h4>
                        {
                            officers.map((user) => (
                                <PublicOfficer key={user.uid} data={user} navigateUrl="/user/" />
                            ))
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PublicClub