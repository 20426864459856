import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const MemberProtected = ({ Component }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkLogin = () => {
            const login = localStorage.getItem("_memberAuth");
            if (login) {
                setIsAuthenticated(true);
            } else {
                navigate(`/login`);
            }
        };

        checkLogin();
    }, [navigate]);

    if (!isAuthenticated) {
        return null; // Render nothing while checking authentication
    }

    return (
        <div>
            <Component />
        </div>
    );
};
export default MemberProtected