import React from 'react'
import AdminSidePanel from '../../components/admin/AdminSidePanel'
import { Outlet } from 'react-router-dom'

const Admin = () => {
    return (
        <>
            <div id='sidebar'>
                <AdminSidePanel />
            </div>
            <div id='main-content'>
                <Outlet />
            </div>
        </>
    )
}

export default Admin