import React, {useEffect, useState} from 'react';
import {Alert, Container, Form, InputGroup, Spinner} from "react-bootstrap";
import Clubs from "../../components/Clubs";
import {Search} from "react-bootstrap-icons";

const PublicClubsRoute = () => {

    const [clubs, setClubs] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = useState('')
    const [data, setData] = React.useState([]);
    const [search, setSearch] = React.useState('');
    
    
    React.useEffect(() => {
        ; (async () => {
            setLoading(true);
            const response = await fetch("/v1/clubs", { method: "GET", headers: { 'authorization': localStorage.getItem("_memberAuth") } })
            if (response.ok) {
                const json = await response.json()
                setClubs(json)
                setData(json)
            }else{
                setError(response.statusText)
            }
            setLoading(false);
        })()
    },[])

    useEffect(() => {
        let filteredClubs = clubs.filter((club) =>
            club.club_name.toLowerCase().includes(search.toLowerCase())
        )
        setData(filteredClubs);
    }, [clubs, search]);

    return (
        <Container fluid={true}>

            {
                loading ?
                    <div className={'d-flex justify-content-center align-items-center'}>
                        <Spinner role={"alert"} className={'m-2'}></Spinner>
                    </div>
                    : <></>
            }
            {
                error ? <Alert variant={'danger'}>{error}</Alert> : <></>
            }

            <div>
                <Form className="mt-2">
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1"><Search/></InputGroup.Text>
                        <Form.Control
                            placeholder="Club Name"
                            aria-label="clubName"
                            value={search}
                            onChange={e=> {setSearch(e.target.value)}}
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </Form>
            </div>

            {
                data.map((club) => (
                    <Clubs data={club} navigateUrl={'/club/'} key={club.id}/>
                ))
            }
        </Container>
    );
};

export default PublicClubsRoute;