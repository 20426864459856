import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const AdminProtected = ({ Component }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkLogin = () => {
            const login = localStorage.getItem("_adminAuth");
            if (login) {
                setIsAuthenticated(true);
            } else {
                window.location.href = `https://sso.messant.in?callback=${window.location.protocol}//${window.location.hostname}:${window.location.port}/adminlogin&app_id=321e`
            }
        };

        checkLogin();
    }, [navigate]);

    if (!isAuthenticated) {
        return null; // Render nothing while checking authentication
    }

    return (
        <div>
            <Component />
        </div>
    );
}

export default AdminProtected