import React from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import {BuildingFill} from "react-bootstrap-icons";

const Clubs = ({ data, navigateUrl }) => {
    const navigate = useNavigate()
    const viewClub = () => {
        navigate(navigateUrl + data.cid)
    }

    return (
        <Card className='mt-2 mb-2'>
            <CardHeader>
                <CardTitle>{data.club_name}</CardTitle>
            </CardHeader>
            <CardBody>
                <Card.Text>{data.club_address}</Card.Text>
            </CardBody>
            <CardFooter>
                <Button onClick={viewClub}><BuildingFill/> View</Button>
            </CardFooter>
        </Card>
    )
}

export default Clubs