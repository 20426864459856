import React, {useCallback, useEffect, useState} from 'react'
import {Container, Navbar, Row, Col, NavbarBrand, Modal, InputGroup, Form, Button} from 'react-bootstrap'
import AdminRegion from "../../components/admin/AdminRegion";

const Regions = () => {

    const [content, setContent] = useState('')
    const [modal, setModal] = useState(false)
    const [data, setData] = useState([])
    const handleClose = () => setModal(false);
    const [activeCp, setActiveCp] = React.useState({rid: '', uid: ''});


    const fetchData = useCallback(async ()=> {
        try {
            const response = await fetch("/v1/region", { method: "GET", headers: { 'authorization': localStorage.getItem("_adminAuth") } })
            const data = await response.json()
            setData(data)
        }catch (e) {

        }finally {

        }
    }, [])

    const editRC = useCallback((data) => {
        setModal(true);
        setActiveCp({...activeCp, rid: data.rid, uid: data.uid})
    }, [activeCp])

    useEffect(() => {
        setContent(
            <Row>
                {data.map((reg) => (
                    <Col lg={3} key={reg.rid}>
                        <AdminRegion data={reg} editHandler={editRC}/>
                    </Col>
                ))}
            </Row>
        )
    },[data, editRC])



    useEffect(() => {
        fetchData().then()
    }, [fetchData])

    const changeCP = async ()=> {
        const response = await fetch("/v1/region", {method: "PUT", headers: { 'authorization': localStorage.getItem("_adminAuth"), 'Content-Type': 'application/json' }, body: JSON.stringify(activeCp) })
        if (response.ok) {
            await fetchData()
        }
    }




    return (
        <>
            <Navbar expand="lg" bg="dark" className='border-bottom sticky-top' data-bs-theme="dark">
                <Container fluid>
                    <NavbarBrand>Regions</NavbarBrand>
                </Container>
            </Navbar>
            <Container fluid>
                {content}
            </Container>

            <Modal show={modal} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Region management</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Member ID</InputGroup.Text>
                        <Form.Control
                            name='id'
                            value={activeCp.uid}
                            onChange={e => setActiveCp({...activeCp, uid: e.currentTarget.value})}
                            placeholder="Member ID"
                            aria-label="PublicCabinetRoute role"
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={changeCP}>Change CP</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Regions