import React, { useState } from 'react'
import { Navbar, Container, Form, Button, Row, Col, Spinner } from 'react-bootstrap'
import Member from '../../components/Member'
import MemberInfo from '../../components/MemberInfo'
import Empty from "../../components/Empty";

const AdminMembersRoute = () => {
    document.title = "Members"
    const [inputValue, setInputValue] = useState('')
    const [member, setMember] = useState({})
    const [members, setMembers] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const memberCLick = (data) => {
        setMember(data)
    }

    const searchMember = async () => {
        setLoading(true)
        const response = await fetch(`/v1/member?q=${inputValue}`, { method: 'GET', headers: { 'Authorization': localStorage.getItem("_adminAuth") } })

        if (response.ok) {
            const data = await response.json()
            setLoading(false)
            setMembers(data)
        } else {
            setLoading(false)
            setError(response.statusText)
        }
    }

    return (
        <>
            <Navbar expand="lg" bg="dark" className='border-bottom sticky-top' data-bs-theme="dark">
                <Container fluid>
                    <Form className="d-flex">
                        <Form.Control type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder="Name or Phone or Member ID" />
                        <Button className="ms-2" onClick={() => searchMember()}>Search</Button>
                    </Form>
                </Container>
            </Navbar>

            <Container fluid>
                <Row>
                    <Col lg={6}>
                        {
                            loading ? (
                                <div className='mt-4 d-flex justify-content-center align-items-center'>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            ) : error ? (
                                <>{error}</>
                            ) : (
                                <>
                                    {
                                        members.length > 0
                                            ?
                                            members.map((item) => (
                                                <Member key={item.uid} data={item} memberClick={memberCLick} />
                                            ))
                                            : <Empty/>
                                    }
                                </>
                            )
                        }
                    </Col>
                    <Col lg={6}>
                        {member.uid > 0 ? (
                            <MemberInfo member={member} />
                        ) : (
                            <Empty/>
                        )
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AdminMembersRoute