import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {Col, Container, Navbar, NavbarBrand, Row, Modal, Button, Form, Spinner, InputGroup} from 'react-bootstrap'
import {BuildingFill, PlusLg} from "react-bootstrap-icons";
import AdminOfficer from "../../components/admin/AdminOfficer";
import AdminClubMember from "../../components/admin/AdminClubMember";


const AdminClubRoute = () => {
    const navigate = useNavigate()
    const [modal, setModal] = React.useState(false)
    const [addMemberModal, setAddMemberModel] = React.useState(false)
    const [newMember, setNewMember] = React.useState('')
    const { id } = useParams()
    const [club, setClub] = React.useState({})
    const [users, setUsers] = useState([])
    const [officers, setOfficers] = useState([])
    const [currentUpgrade, setCurrentUpgrade] = useState({uid: '', role: ''})
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setModal(false)
        setAddMemberModel(false)
        setCurrentUpgrade({...currentUpgrade, uid: '', role: ''})
    }


    useEffect(() => {
        ; (async ()=> {
            const response = await fetch("/v1/clubs/" + id, { method: "GET", headers: {Authorization: localStorage.getItem("_adminAuth")} })
            const data = await response.json()
            setUsers(data.members)
            setOfficers(data.officers)
            setClub(data.club)
        })()
    }, [id])

    const fetchAll = async () => {
        const response = await fetch("/v1/clubs/" + id, { method: "GET", headers: {Authorization: localStorage.getItem("_adminAuth")} })
        const data = await response.json()
        setUsers(data.members)
        setOfficers(data.officers)
        setClub(data.club)
    }


    const deleteOfficer = async (id) => {
        const newData = officers.filter(officer => officer.oid !== id);
        setOfficers(newData);
        alert(`Officer removed successfully`)
        await fetch("/v1/officer/" + id, { method: "DELETE", headers: {Authorization: localStorage.getItem("_adminAuth")} })
    }

    const makeOfficer = (uid) => {
        setModal(true)
        setCurrentUpgrade({...currentUpgrade, uid:uid})
    }

    const deleteMember = async (uid) => {
        const newData = users.filter(user => user.uid !== uid);
        setUsers(newData);
        await fetch(`/v1/member/${uid}`, {
            method: 'DELETE',
            headers: {Authorization: localStorage.getItem("_adminAuth")}
        })
    }

    const editMember = (uid) => {
        navigate(`/admin/member/${uid}`)
    }
    const finalUpgrade = async () => {
        setLoading(true)
        const response = await fetch("/v1/officer/", { method: "POST", body: JSON.stringify({...currentUpgrade, cid: id}), headers: {Authorization: localStorage.getItem("_adminAuth"), 'Content-Type': 'application/json'} })

        if (response.ok) {
            await fetchAll()
            handleClose()
            setLoading(false)
        }else{
            setLoading(false)
        }
    }
    
    const addMember = async () => {
        const response = await fetch("/v1/member", { method: "POST", body: JSON.stringify({uid: newMember, cid: id}), headers: {Authorization: localStorage.getItem("_adminAuth"), 'Content-Type': 'application/json'} })

        if (response.ok) {
            navigate(`/admin/member/${newMember}`)
        }
    }

    return (
        <>
            <Navbar expand="lg" bg="dark" className='border-bottom sticky-top' data-bs-theme="dark">
                <Container fluid>
                    <NavbarBrand>
                        <BuildingFill/> {club.club_name}
                    </NavbarBrand>
                    <Button onClick={()=>setAddMemberModel(true)}><PlusLg/> Add Member</Button>
                </Container>
            </Navbar>
            <Container fluid>
                <Row>
                    <Col lg={6}>
                        <Row>
                            {
                                users.map((user) => (
                                    <Col lg={6} md={6} sm={12} key={user.uid}>
                                        <AdminClubMember key={user.uid} data={user} editHandler={editMember} upgradeHandler={makeOfficer} deleteHandler={deleteMember}/>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                    <Col lg={6}>
                        <Row>
                            {
                                officers.map((user) => (
                                    <Col lg={4} md={6} sm={12} key={user.uid}>
                                        <AdminOfficer data={user} deleteHandler={deleteOfficer}/>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Modal show={modal} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Upgrade Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Label>Role</Form.Label>
                        <Form.Control type='text' name='role' value={currentUpgrade.role} onChange={(e)=>setCurrentUpgrade({...currentUpgrade, role: e.target.value})}></Form.Control>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {
                        loading ?
                            <Spinner role='alert'>
                            </Spinner>
                            :
                            <Button variant="primary" onClick={finalUpgrade}>Save</Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={addMemberModal} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>SYSTEM</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Member ID</InputGroup.Text>
                        <Form.Control
                            value={newMember}
                            onChange={event => setNewMember(event.target.value)}
                            placeholder="Member ID"
                            aria-label="PublicCabinetRoute role"
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={addMember}><PlusLg/> Add</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AdminClubRoute