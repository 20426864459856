import React, { useState } from 'react'
import { Button, Card, CardFooter, CardHeader, CardTitle, Modal } from 'react-bootstrap'

const PublicOath = ({ message }) => {

    const [content, setContent] = useState('')
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const showEnglish = () => {
        handleShow()
        setContent(message.body_english)
    }

    const showHindi = () => {
        handleShow()
        setContent(message.body_hindi)
    }

    return (
        <>
            <Card className='mt-2 mb-2'>
                <CardHeader>
                    <CardTitle>{message.label}</CardTitle>
                </CardHeader>
                <CardFooter>
                    <Button className='me-2' onClick={showHindi}>Hindi</Button>
                    <Button variant='info' onClick={showEnglish}>English</Button>
                </CardFooter>
            </Card>


            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {content}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PublicOath