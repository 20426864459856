import React, {useState} from 'react';
import {Alert, Button, Col, Container, Form, InputGroup, Modal, Navbar, Row, Spinner} from "react-bootstrap";
import Clubs from "../../components/Clubs";
import {BuildingFillAdd, PlusLg} from "react-bootstrap-icons";

const AdminClubsRoute = () => {

    const [clubs, setClubs] = React.useState([]);
    const [backUpClubs, setBackUpClubs] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [inputValue, setInputValue] = useState('')
    const [show, setShow] = React.useState(false);
    const [newClub, setNewClub] = React.useState({name : '', address: ''});
    const handleClose = () => {
        setShow(false);
        // setProgress({variant: '', message: ''});
    };
    const handleShow = () => setShow(true);

    React.useEffect(() => {
        ; (async ()=> {
            const response = await fetch(`/v1/clubs`, {method: "GET", headers: {"Content-Type": "application/json", Authorization: localStorage.getItem("_adminAuth")}});

            if (response.ok) {
                const data = await response.json();
                setClubs(data);
                setBackUpClubs(data)
                setLoading(false);
            }else{
                setLoading(false);
            }
        })()
    },[])

    React.useEffect(() => {
        const filteredClubs = backUpClubs.filter((club) =>
            club.club_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setClubs(filteredClubs);
    },[inputValue, backUpClubs])


    const addClubHandler = () => {
        handleShow()
    }

    const addClub = async () => {
        await fetch(`/v1/clubs`, {method: "POST", headers: {"Content-Type": "application/json", Authorization: localStorage.getItem("_adminAuth")}, body: JSON.stringify(newClub)});
        alert("Club added successfully. Please refresh the page.");
    }

    return(
        <>
            <Navbar expand="lg" bg="dark" className='border-bottom sticky-top' data-bs-theme="dark">
                <Container fluid>
                    <Form>
                        <Form.Control type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder="Search club" />
                    </Form>
                    <Button onClick={addClubHandler}><BuildingFillAdd/> Add club</Button>
                </Container>
            </Navbar>
            <Container fluid={true}>
                {
                    loading ?
                        <div className='d-flex justify-content-center align-items-center'>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    :
                    <Row>
                        {clubs.map((club) => (<Col lg={3} key={club.cid}><Clubs data={club} navigateUrl='/admin/club/' /></Col>))}
                    </Row>
                }
            </Container>



            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Club Manager</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Club name</InputGroup.Text>
                        <Form.Control
                            value={newClub.name}
                            onChange={e => setNewClub({...newClub, name: e.currentTarget.value})}
                            placeholder="Club name"
                            aria-label="PublicCabinetRoute role"
                        />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Address</InputGroup.Text>
                        <Form.Control
                            value={newClub.address}
                            onChange={e => setNewClub({...newClub, address: e.currentTarget.value})}
                            placeholder="Club address"
                            aria-label="Member ID"
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={addClub} variant="primary" ><PlusLg/> Add</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default AdminClubsRoute;