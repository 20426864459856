import React from 'react';
import {Button, Card} from "react-bootstrap";
import {Pencil, PersonFillGear, PhoneFill, Trash2Fill} from "react-bootstrap-icons";

const AdminClubMember = ({data, editHandler, upgradeHandler, deleteHandler}) => {
    return (
    <Card className='mt-2 mb-2'>
        <Card.Body>
            <div className='d-flex align-items-center'>
                <img width={64} height={64} className='rounded-2 me-2' src={(data.picture === null) ? "/lion.png" : data.picture} alt={data.name} />
                <div>
                    <Card.Title>{data.name}</Card.Title>
                    <Card.Text><PhoneFill/> {data.mobile}</Card.Text>
                </div>
            </div>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between">
            <div>
                <Button variant="outline-success" className='me-2' onClick={()=>upgradeHandler(data.uid)}><PersonFillGear/> Make Officer</Button>
                <Button variant='info' onClick={()=>editHandler(data.uid)}><Pencil/></Button>
            </div>
            <Button variant="outline-danger" onClick={()=>deleteHandler(data.uid)}><Trash2Fill/></Button>
        </Card.Footer>
    </Card>
    );
};

export default AdminClubMember;