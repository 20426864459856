import React, {useCallback, useEffect, useState} from 'react'
import {Button, NavbarBrand, Container, Navbar, Row, Col, Card, Table, Modal, Spinner} from 'react-bootstrap'
import {
    BoxArrowRight,
    BuildingsFill, CreditCardFill,
    PeopleFill,
    PersonBadgeFill,
    PersonFillGear,
    Speedometer2, WalletFill
} from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import useRazorpay from "react-razorpay";
import {RazorpayOptions} from "react-razorpay";



const AdminDashboard = () => {

    const [Razorpay] = useRazorpay();

    document.title = 'Admin Dashboard';
    const navigate = useNavigate();
    const [data, setData] = useState({ members: 0, clubs: 0, officers: 0, cabinets: 0, amount: 0, limit: 0 });
    const [logs, setLogs] = useState([])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const fetchData = useCallback(async () => {
        try {
            const response = await fetch('/v1/dashboard', {
                method: 'GET',
                headers: { 'Authorization': localStorage.getItem('_adminAuth') }
            });
            if (response.ok) {
                const dataResponse = await response.json();
                setData({
                    members: dataResponse[0][0].members,
                    clubs: dataResponse[1][0].clubs,
                    officers: dataResponse[2][0].officers,
                    cabinets: dataResponse[3][0].cabinets,
                    amount: dataResponse[4][0].amount,
                    limit: dataResponse[4][0].limit,
                });
                setLogs(dataResponse[5][0]);
            } else {
                // Handle response errors here
                console.error('Failed to fetch data');
            }
        } catch (error) {
            // Handle network errors here
            console.error('Error fetching data:', error);
        }
    }, []);

    useEffect(() => {
        fetchData().then(r => {});
    }, [fetchData]);

    const logout = () => {
        localStorage.clear()
        navigate('/')
    }


    const billPayment = useCallback(async (data) => {
        const response = await fetch('/v1/bill', {
            method: 'PUT',
            headers: { 'Authorization': localStorage.getItem('_adminAuth'), 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            window.location.reload();
        }
    },[])


    const payNow = useCallback(async () => {

        handleShow()
        const response = await fetch('/v1/bill', {
            method: 'GET',
            headers: { 'Authorization': localStorage.getItem('_adminAuth') }
        });

        if (!response.ok) {
            handleClose()
        }
        let orderPre = await response.json();
        let order = orderPre.data
        const options: RazorpayOptions = {
            key: order.key,
            amount: order.amount,
            currency: "INR",
            name: "MESSANT TECHNOLOGIES",
            description: "Test Transaction",
            image: "/lion.png",
            order_id: order.id,
            handler: async (res) => {
                console.log(res);
                await billPayment(res)
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
            billPayment({razorpay_order_id: response.error.metadata.order_id, razorpay_payment_id: response.error.metadata.payment_id})
        });

        rzp1.open();
    }, [Razorpay, billPayment]);

    


    return (
        <>
            <Navbar expand="lg" bg="dark" className='border-bottom sticky-top' data-bs-theme="dark">
                <Container fluid>
                    <NavbarBrand>
                        <Speedometer2 /> Dashboard
                    </NavbarBrand>
                    <Button variant='danger' onClick={logout}><BoxArrowRight /> Logout</Button>
                </Container>
            </Navbar>

            <Container fluid>
                <Row>
                    <Col lg={3} md={12}>
                        <Row>
                            <Col lg={12}>
                                <Card className='mt-2 mb-2' bg={'primary'}>
                                    <Card.Header>
                                        <Card.Title>
                                            MEMBERS
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className='d-flex justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                            <h1>{data.members}</h1>
                                        </div>
                                        <div>
                                            <PeopleFill size={96}/>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <Card className='mt-2 mb-2' bg={'success'}>
                                    <Card.Header>
                                        <Card.Title>
                                            CLUBS
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className='d-flex justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                            <h1>{data.clubs}</h1>
                                        </div>
                                        <div>
                                            <BuildingsFill size={96}/>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <Card className='mt-2 mb-2' bg = {'secondary'}>
                                    <Card.Header>
                                        <Card.Title>
                                            OFFICERS
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className='d-flex justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                            <h1>{data.officers}</h1>
                                        </div>
                                        <div>
                                            <PersonFillGear size={96}/>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <Card className='mt-2 mb-2' bg={'warning'} data-bs-theme="light">
                                    <Card.Header>
                                        <Card.Title>
                                            CABINETS
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className='d-flex justify-content-between'>
                                        <div className='d-flex align-items-center'>
                                            <h1>{data.cabinets}</h1>
                                        </div>
                                        <div>
                                            <PersonBadgeFill size={96}/>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={3} md={12}>
                        <Card className='mt-2 mb-2' bg={'secondary'}>
                            <Card.Header>
                                <Card.Title>
                                    SMS BILL
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className='d-flex justify-content-between'>
                                <div>
                                    <h1>₹ {data.amount}</h1>
                                    <h5>Limit {data.limit}</h5>
                                </div>
                                <div>
                                    <WalletFill size={96}/>
                                </div>
                            </Card.Body>
                            <Card.Footer>
                                <Button variant={'dark'} className={'w-100'} disabled={data.amount < 10} onClick={payNow}><CreditCardFill/> Pay now</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col lg={6} md={12}>
                        <Table striped bordered hover className="mt-2 rounded-2">
                            <thead>
                            <tr>
                                <th>Phone no</th>
                                <th>Code</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                logs.map((log, index) => (
                                    <tr key={index}>
                                        <td>{log.phone}</td>
                                        <td>{log.code}</td>
                                        <td>{log.status}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Checkout in progress</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-center align-items-center">
                        <Spinner animation="grow" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AdminDashboard