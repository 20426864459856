import React from 'react';
import {PersonWorkspace, PhoneFill, XLg} from "react-bootstrap-icons";
import {Badge, Card, Button} from "react-bootstrap";

const AdminOfficer = ({data, deleteHandler}) => {

    const lists = {
        "Club Treasurer": "secondary",
        "Club President": "primary",
        "Club Secretary": "success"
    }

    return (
        <>
            <Card className='mt-2 mb-2'>
                <Card.Img variant="top" src={(data.picture === null) ? "/lion.png" : data.picture}/>
                <Card.Body>
                    <Card.Title>{data.name}</Card.Title>
                    <label><Badge bg={lists[data.role]}><PersonWorkspace/> {data.role}</Badge></label><br/>
                    <Card.Text><PhoneFill/> {data.mobile}</Card.Text>
                    <Button variant="outline-danger" className='w-100' onClick={()=>deleteHandler(data.oid)}><XLg/> Dismiss</Button>
                </Card.Body>
            </Card>
        </>
    )
}

export default AdminOfficer;

