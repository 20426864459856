import React from 'react';
import {Button, Card, CardBody, CardFooter, CardHeader, CardSubtitle} from "react-bootstrap";
import {BuildingFill, BuildingFillAdd, PersonFillGear, TrashFill} from "react-bootstrap-icons";

const AdminZone = ({ data, changeHandler, viewHandler, addHandler, deleteHandler }) => {
    return (
        <Card className='mt-2 mb-2'>
            <CardHeader>
                <Card.Title>{data.label}</Card.Title>
                <CardSubtitle>{data.name}</CardSubtitle>
            </CardHeader>
            <CardBody>
                <Card.Text>{data.club_name}</Card.Text>
                <Card.Text>{data.mobile || 'No Phone number'}</Card.Text>
            </CardBody>
            <CardFooter className='d-flex justify-content-between'>
                <Button onClick={() => viewHandler(data.zid)} className='me-2'><BuildingFill /> View clubs</Button>
                <div>
                    <Button variant={'danger'} className={'me-2'} onClick={() => deleteHandler(data)}><TrashFill/></Button>
                    <Button variant='info' className='me-2' onClick={() => changeHandler(data)}><PersonFillGear /></Button>
                    <Button variant='success' onClick={() => addHandler(data.zid)}><BuildingFillAdd /></Button>
                </div>
            </CardFooter>
        </Card>
    )
};

export default AdminZone;