import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UserHome from './routes/user/UserHome';
import Admin from './routes/admin/Admin';
import Regions from './routes/admin/AdminRegionsRoute';
import AdminLogin from './routes/login/AdminLogin';
import AdminProtected from './auth/AdminProtected';
import AdminZoneRoute from './routes/admin/AdminZoneRoute';
import AdminClubRoute from './routes/admin/AdminClubRoute';
import AdminUserRoute from './routes/admin/AdminUserRoute';
import MemberProtected from './auth/MemberProtected';
import MemberLogin from './routes/login/MemberLogin';
import PublicBanners from './routes/user/PublicBanners';
import PublicPageRoute from './routes/user/PublicPageRoute';
import PublicListRoute from './routes/user/PublicListRoute';
import PublicUserView from './routes/user/PublicUserView';
import PublicRegions from './routes/user/PublicRegions';
import PublicZone from './routes/user/PublicZone';
import PublicClub from './routes/user/PublicClub';
import PublicCabinetRoute from './routes/user/PublicCabinetRoute';
import AdminDashboard from './routes/admin/AdminDashboardRoute';
import AdminBannerRoute from './routes/admin/AdminBannerRoute';
import AdminMessageRoute from './routes/admin/AdminMessageRoute';
import AdminMembersRoute from './routes/admin/AdminMembersRoute';
import AdminClubsRoute from "./routes/admin/AdminClubsRoute";
import AdminCabinetRoute from "./routes/admin/AdminCabinetRoute";
import Error404 from "./components/Error404";
import AdminListRoute from "./routes/admin/AdminListRoute";
import PublicOathRoute from "./routes/user/PublicOathRoute";
import PublicClubsRoute from "./routes/user/PublicClubsRoute";
import PublicProfileRoute from "./routes/user/PublicProfileRoute";
import Auth from "./routes/login/Auth";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<MemberProtected Component={UserHome} />}>
          <Route path='' element={<PublicBanners />} />
          <Route path='/oath' element={<PublicOathRoute />} />
          <Route path='page/:id' element={<PublicPageRoute />} />
          <Route path='list/:id' element={<PublicListRoute />} />
          <Route path='clubs' element={<PublicClubsRoute />} />
          <Route path='club/:id' element={<PublicClub />} />
          <Route path='cabinet' element={<PublicCabinetRoute />} />
          <Route path='region' element={<PublicRegions />} />
          <Route path='zone/:id' element={<PublicZone />} />
          <Route path='user/:id' element={<PublicUserView />} />
          <Route path='profile' element={<PublicProfileRoute />} />
        </Route>

        <Route path='/auth' element={<Auth />} />
        <Route path='/login' element={<MemberLogin />} />
        <Route path='/adminlogin' element={<AdminLogin />} />

        <Route path="/admin" element={<AdminProtected Component={Admin} />}>
          <Route path='' element={<AdminDashboard />} />
          <Route path='dashboard' element={<AdminDashboard />} />
          <Route path='banners' element={<AdminBannerRoute />} />
          <Route path='messages' element={<AdminMessageRoute />} />
          <Route path='members' element={<AdminMembersRoute />} />
          <Route path='clubs' element={<AdminClubsRoute />} />
          <Route path='club/:id' element={<AdminClubRoute />} />
          <Route path='cabinet' element={<AdminCabinetRoute/>}/>
          <Route path='zone/:id' element={<AdminZoneRoute />} />
          <Route path='regions' element={<Regions />} />
          <Route path='member/:id' element={<AdminUserRoute />} />
          <Route path='list/:id' element={<AdminListRoute />} />
        </Route>

        <Route path='*' element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
