import React, {useEffect, useState} from 'react';
import PublicOath from "../../components/user/PublicOath";

const PublicOathRoute = () => {

    const [messages, setMessages] = useState([])

    useEffect(()=> {
        ; (async ()=>{
            const response = await fetch(`/v1/messages`, { method: "GET", headers: { 'authorization': localStorage.getItem("_memberAuth") } })
            if (response.ok) {
                const json = await response.json()
                setMessages(json)
            } else {
                alert("Network issue")
            }
        })()
    },[])

    return (
        <div>
            {
                messages.map((message) => (
                    <PublicOath message={message} key={message.mid} />
                ))
            }
        </div>
    );
};

export default PublicOathRoute;