import React from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, CardSubtitle } from 'react-bootstrap'
import {BuildingFill, BuildingFillAdd, PersonFillGear} from 'react-bootstrap-icons'

const Zones = ({ data, user, viewHandler, addHandler }) => {
    return (
        <Card className='mt-2 mb-2'>
            <CardHeader>
                <Card.Title>{data.label}</Card.Title>
                <CardSubtitle>{data.name}</CardSubtitle>
            </CardHeader>
            <CardBody>
                <Card.Text>{data.club_name}</Card.Text>
                <Card.Text>{data.mobile}</Card.Text>
            </CardBody>
            <CardFooter className='d-flex justify-content-between'>
                <Button onClick={() => viewHandler(data.zid)} className='me-2'><BuildingFill /> View clubs</Button>
                <div>
                    {
                        user ? "" : <><Button variant='info' className='me-2' onClick={() => addHandler(data.zid)}><PersonFillGear /></Button><Button variant='success' onClick={() => addHandler(data.zid)}><BuildingFillAdd /></Button></>
                    }
                </div>
            </CardFooter>
        </Card>
    )
}

export default Zones