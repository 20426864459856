import React, { useState, useEffect } from 'react'
import {Link, useParams} from 'react-router-dom'
import {Card, CardTitle, CardHeader, CardBody, Row, Col, Container} from 'react-bootstrap'

const PublicUserView = () => {

    const [data, setData] = useState({})
    const { id } = useParams()

    useEffect(() => {
        ; (async () => {
            const response = await fetch("/v1/member/" + id, { method: "GET", headers: { 'authorization': localStorage.getItem("_memberAuth") } })
            const data = await response.json()
            setData(data)
        })()
    }, [id])

    return (
        <>
            <Container>
                <Card className="mt-2 mb-2">
                    <CardHeader>
                        <CardTitle>
                            Member Information
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <div className="d-flex justify-content-center align-items-center">
                            <img className="rounded" src={(data.picture === null) ? "/lion.png" : data.picture} width={128} height={128} alt="data profile" />
                        </div>
                        <hr />
                        <Row>
                            <Col lg={6}>

                                <label><b>Member ID</b></label>
                                <p>{data.uid}</p>

                                <label><b>Name</b></label>
                                <p>{data.name}</p>

                                <label><b>Phone number</b></label>
                                <p><Link to={'tel:' + data.mobile}>{data.mobile}</Link> </p>

                                <label><b>Email</b></label>
                                <p><Link to={'mailto:' + data.email}>{data.email}</Link></p>

                                <label><b>Club Name</b></label>
                                <p>{data.club_name}</p>
                            </Col>
                            <Col lg={6}>
                                <label><b>Address</b></label>
                                <p>{data.address}</p>

                                <label><b>Profession</b></label>
                                <p>{data.profession}</p>

                                <label><b>Blood group</b></label>
                                <p>{data.blood}</p>

                                <label><b>Date of birth</b></label>
                                <p>{new Date(data.dob).toDateString()}</p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </>
    )
}

export default PublicUserView