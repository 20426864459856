import React, { useState, useEffect } from 'react'
import {Card, CardBody, CardFooter, CardHeader, CardTitle, Spinner} from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Empty from "../../components/Empty";

const PublicListRoute = () => {

    const { id } = useParams()
    const [lists, setList] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        ; (async () => {
            const response = await fetch(`/v1/list/${id}`, { method: "GET", headers: { 'authorization': localStorage.getItem("_memberAuth") } })
            setLoading(false)
            if (response.ok) {
                const json = await response.json()
                setList(json)
            } else {

            }
        })()
    }, [id])



    return (
        <>
            {
                loading ?
                    <div className={'d-flex justify-content-center align-items-center'}>
                        <Spinner role={"alert"} className={'m-2'}></Spinner>
                    </div>
                    : <></>
            }
            {
                lists.length === 0 ? <Empty/> : <></>
            }
            {
                lists.map((list, index) => (
                    <Card key={index} className='m-2'>
                        <CardHeader>
                            <CardTitle>{list.heading}</CardTitle>
                        </CardHeader>
                        <CardBody>
                            {list.body}
                        </CardBody>
                        <CardFooter>
                            {list.info}
                        </CardFooter>
                    </Card>
                ))
            }
    </>
    )
}

export default PublicListRoute