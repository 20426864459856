import React, { useEffect, useState } from 'react'
import {Navbar, Container, NavbarBrand, Offcanvas} from 'react-bootstrap'
import {
    BrowserSafari,
    BuildingsFill, HouseFill,
    List,
    ListUl,
    PeopleFill, Quote, XLg
} from 'react-bootstrap-icons';
import { Link, Outlet } from 'react-router-dom';
import {jwtDecode} from "jwt-decode";

const UserHome = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [data, setData] = useState({name: 'Guest', club_name: 'Guest Club', picture: '/lion.png'});
    let ac= localStorage.getItem("accountData")

    useEffect(() => {
        ; (async () => {
            const jwt = jwtDecode(localStorage.getItem("_memberAuth"));
            if (jwt.role === "user"){
                if (!ac){
                    const response = await fetch("/v1/account", { method: "GET", headers: { 'authorization': localStorage.getItem("_memberAuth") } })
                    if (response.ok) {
                        const json = await response.json()
                        setData(json)
                        localStorage.setItem("accountData", JSON.stringify(json))
                    }
                }else{
                    setData(JSON.parse(ac));
                }
            }
        })()
    }, [ac])


    return (
        <>
            <Navbar expand="lg" style={{ background: '#E4CEAE' }} className='border-bottom sticky-top' data-bs-theme="light">
                <Container fluid>
                    <NavbarBrand onClick={handleShow} role='button'> <List /> District 321E</NavbarBrand>

                    <div>
                        <Link to={'/profile'}>
                            <img src={data.picture} alt={data.name} className={'rounded-circle'} role={'button'}
                                         width={40} height={40}/>
                        </Link>
                    </div>
                </Container>
            </Navbar>

            <Offcanvas id="offcanvas" show={show} onHide={handleClose} data-bs-theme="light">
                <Offcanvas.Header style={{ background: '#E4CEAE' }} className={'d-flex justify-content-between'}>
                    <Offcanvas.Title>District 321E | 2024 - 25</Offcanvas.Title>
                    <button className={'close-btn'} onClick={handleClose}><XLg size={32}/></button>
                </Offcanvas.Header>
                <div style={{ background: '#E4CEAE' }} className='ps-2'>
                    <img id="imgUser"
                        src={data.picture}
                        width={56} height={56} alt='user-image' className={'rounded-2'} />
                    <h4 id="textUserName" className="mt-2">{data.name}</h4>
                    <p id="textUserDesignation">{data.club_name}</p>

                </div>
                <Offcanvas.Body style={{ background: "#741029" }}>
                    <div className="mt-3">
                        <ul>
                            <li>
                                <Link to={'/'} onClick={handleClose} className={'d-flex align-items-center'}>
                                    <HouseFill/> <span className={'ms-2'}>Home</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/oath'} onClick={handleClose} className={'d-flex align-items-center'}>
                                    <Quote/> <span className={'ms-2'}>Oath</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/page/founder'} onClick={handleClose} className={'d-flex align-items-center'}>
                                    <BrowserSafari/> <span className={'ms-2'}>MELVIN JONES, Founder</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/list/office'} onClick={handleClose} className={'d-flex align-items-center'}>
                                    <ListUl/> <span className={'ms-2'}>Area and District Office</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/list/pips'} onClick={handleClose} className={'d-flex align-items-center'}>
                                    <ListUl/> <span className={'ms-2'}>PIPs from India</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/list/pid'} onClick={handleClose} className={'d-flex align-items-center'}>
                                    <ListUl/> <span className={'ms-2'}>Past International Directors</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/list/pdg'} onClick={handleClose} className={'d-flex align-items-center'}>
                                    <ListUl/> <span className={'ms-2'}>Past District Governors</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/clubs'} onClick={handleClose} className={'d-flex align-items-center'}>
                                    <BuildingsFill/> <span className={'ms-2'}>Clubs</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/cabinet'} onClick={handleClose} className={'d-flex align-items-center'}>
                                    <PeopleFill/> <span className={'ms-2'}>Cabinet</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/region'} onClick={handleClose} className={'d-flex align-items-center'}>
                                    <BuildingsFill/> <span className={'ms-2'}>Regions</span>
                                </Link>
                            </li>
                        </ul>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>

            <Container style={{maxWidth: '800px'}}>
                <div>
                    <Outlet/>
                </div>
            </Container>
        </>
    )
}

export default UserHome