import React, { useState, useEffect } from 'react'

import { Carousel } from 'react-bootstrap'


const PublicBanners = () => {
    const [banners, setBanners] = useState([])

    useEffect(() => {
        ; (async () => {
            const response = await fetch("/v1/banners", { method: "GET", headers: { 'authorization': localStorage.getItem("_memberAuth") } })
            if (response.ok) {
                const json = await response.json()
                setBanners(json)
            } else {
                alert("Network issue")
            }
        })()
    }, [])



    return (
        <div>
            <Carousel>

                {
                    banners.map((banner) => (
                        <Carousel.Item key={banner.bid}>
                            <img className="d-block w-100" src={banner.image} alt="First slide" />
                        </Carousel.Item>
                    ))
                }
            </Carousel>
        </div>
    )
}

export default PublicBanners