import React, {useState} from 'react'
import {
    Container,
    Card,
    CardHeader,
    CardBody,
    Form,
    CardFooter,
    Button,
    Alert,
    Spinner
} from 'react-bootstrap';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ArrowRight} from "react-bootstrap-icons";
import {jwtDecode} from "jwt-decode";


const MemberLogin = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const callBack = searchParams.get('callback');
    const [step, setStep] = useState(0)
    const [networkMessage, setNetworkMessage] = useState({variant: '', message: ''})
    const [networkLoading, setNetworkLoading] = useState(false)

    const [token, setToken] = useState('')
    const [currentId, setCurrentId] = useState('')
    const [currentPhone, setCurrentPhone] = useState('')
    const [currentCode, setCurrentCode] = useState('')


    const guestLogin = ()=> {
        window.location.href = `https://sso.messant.in?callback=${window.location.protocol}//${window.location.hostname}:${window.location.port}/auth&app_id=321e`
    }


    const startChecking = async () => {
        if (currentId === '') {
            return
        }
        setNetworkLoading(true)
        const response = await fetch(`/v1/login/${currentId}`)
        setNetworkLoading(false)
        if (response.ok) {
            const data = await response.json()
            if (data.status) {
                setNetworkMessage({variant: '', message: ''})
                setStep(2)
                let jwtData = await jwtDecode(data.message)
                setToken(data.message)
                setCurrentPhone(jwtData.phone)
                await sendCode(jwtData.phone, data.message)
            }else{
                if (data.message === "ID") {
                    setNetworkMessage({variant: 'warning', message: 'Member ID not found'})
                }else {
                    setStep(1)
                    setToken(data.message)
                    setNetworkMessage({variant: '', message: ''})
                }
            }
        }else{
            setNetworkMessage({variant: 'danger', message: response.statusText})
        }
    }

    const sendCode = async (phone, tokenX) => {
        if (phone === '') {
            return
        }
        setNetworkLoading(true)
        const response = await fetch(`/v1/login`, {method: 'POST', body: JSON.stringify({phone: phone, token: tokenX}), headers: {'Content-Type': 'application/json'} })
        setNetworkLoading(false)
        if (response.ok) {
            let data = await response.json()
            if (data.status) {
                setNetworkMessage({variant: '', message: ''})
                setStep(2)
                setToken(data.message)
            }else{
                setNetworkMessage({variant: 'warning', message: data.message})
            }
        }else{
            setNetworkMessage({variant: 'danger', message: response.statusText})
        }
    }

    
    
    
    const verifyCode = async () => {
        if (currentCode === '') {
            return
        }
        setNetworkLoading(true)
        const response = await fetch("/v1/login", { method: "PUT", headers: { 'Content-Type': 'application/json'}, body: JSON.stringify({ code: currentCode, token: token }) })
        setNetworkLoading(false)

        if (response.ok) {
            const d = await response.json()
            if (d.status) {
                localStorage.setItem('_memberAuth', d.message)
                if (callBack){
                    window.location.href = `${callBack}?token=${d.message}`
                }else {
                    navigate('/')
                }
            }else{
                setNetworkMessage({variant: 'warning', message: d.message})
            }
        } else {
            setNetworkMessage({variant: 'danger', message: response.statusText})
        }
    }

    return (
        <Container className='d-flex align-items-center justify-content-center vh-100 mt-4'>
           <div>
               <Card>
                   <CardHeader>
                       <Card.Title className={'text-center'}>
                           Member Login
                       </Card.Title>
                   </CardHeader>
                   <CardBody>

                       {
                           step === 0 ?
                               <Form.Group className="mb-3" controlId="inputID">
                                   <Form.Label>MEMBER ID</Form.Label>
                                   <Form.Control type="text" value={currentId} onChange={e=> {setCurrentId(e.target.value)}} />
                               </Form.Group>
                               : step === 1 ?
                                   <Form.Group className="mb-3" controlId="inputID">
                                       <Form.Label>Enter Your Phone number</Form.Label>
                                       <Form.Control type="text" value={currentPhone} onChange={e=> {setCurrentPhone(e.target.value)}} />
                                   </Form.Group>
                                   : <Form.Group className="mb-3" controlId="inputID">
                                       <Form.Label>OTP</Form.Label>
                                       <Form.Control type="text" value={currentCode} onChange={e=> {setCurrentCode(e.target.value)}} />
                                   </Form.Group>
                       }

                       {
                           networkLoading ?
                               <div className={'d-flex justify-content-center'}><Spinner role={'alert'}></Spinner></div>
                               :
                               <></>
                       }
                       {
                           networkMessage.variant !== '' ?
                               <Alert variant={networkMessage.variant}>
                                   {networkMessage.message}
                               </Alert>
                               : <></>
                       }
                   </CardBody>
                   <CardFooter className={'d-flex align-content-end'}>
                       {
                           step === 0 ? <Button className={'w-100'} onClick={startChecking}>Next <ArrowRight/></Button>
                               : step === 1 ? <Button variant={'info'} className={'w-100'} onClick={()=>sendCode(currentPhone, token)}>Update phone <ArrowRight/></Button>
                                   : <Button variant={'success'} className={'w-100'} onClick={verifyCode}>Verify <ArrowRight/></Button>
                       }
                   </CardFooter>
               </Card>
               <Button variant={'success'} className={'mt-4 w-100'} onClick={guestLogin}>Guest Login</Button>
               <p className={'text-muted mt-4'}>Maintained by <a href={'https://www.messant.in'} className={'text-decoration-none'}>Messant Technologies</a></p>
           </div>
        </Container>
    );
}

export default MemberLogin