import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Region from '../../components/Region'

const PublicRegions = () => {


    const [content, setContent] = useState('')

    useEffect(() => {
        ; (async () => {
            const response = await fetch("/v1/region", { method: "GET", headers: { 'authorization': localStorage.getItem("_memberAuth") } })
            const data = await response.json()

            setContent(
                <Row>
                    {data.map((reg) => (
                        <Col lg={6} key={reg.rid}>
                            <Region data={reg} navigateUrl="/zone/" />
                        </Col>
                    ))}
                </Row>
            )

        })()

    }, [])

    return (
        <Container fluid>
            {content}
        </Container>
    )
}

export default PublicRegions