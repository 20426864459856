import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Col, Container, Form, InputGroup, Navbar, NavbarBrand, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {ListUl, PlusLg} from "react-bootstrap-icons";
import AdminList from "../../components/admin/AdminList";
import Empty from "../../components/Empty";

const AdminListRoute = () => {

    const {id} = useParams();
    const [add, setAdd] = React.useState({heading:'', body: '', info: '', sequence: ''});
    const [data, setLists] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAdd(prevData => {
            return {...prevData, [name]: value};
        });
    };

    const addList = async () => {
        const d = await fetch(`/v1/list/${id}`,
            {method: 'POST',
                headers: {'Authorization': localStorage.getItem("_adminAuth"), 'Accept': 'application/json', 'Content-Type': 'application/json'},
                body: JSON.stringify(add),
            }
        )
        if(d.ok){
            await fetchData()
        }
    }

    const fetchData = useCallback(async () => {
        const d = await fetch(`/v1/list/${id}`,
            {method: 'GET',
                headers: {'Authorization': localStorage.getItem("_adminAuth")}}
        )
        if(d.ok){
            const da = await d.json();
            setLists(da);
            console.log(da)
        }
    },[setLists, id])

    useEffect(()=>{
        fetchData()
    },[fetchData])


    const deleteData = async (id) => {
        let filter = data.filter(item => item.id !== id)
        setLists(filter)
        await fetch('/v1/list/'+id,{method: "DELETE", headers: {Authorization: localStorage.getItem("_adminAuth")}})
    }


    return (
        <>
            <Navbar expand="lg" bg="dark" className='border-bottom sticky-top' data-bs-theme="dark">
                <Container fluid>
                    <NavbarBrand>
                        {id.toUpperCase()}
                    </NavbarBrand>
                </Container>
            </Navbar>
            <Container fluid={true}>
                <Row>
                    <Col lg={8}>
                        {
                            data.length === 0? <Empty/> :
                                <Row>
                                    {
                                        data.map((item) => (
                                            <Col lg={4} key={item.id}>
                                                <AdminList data={item} deleteHandler={deleteData}/>
                                            </Col>
                                        ))
                                    }
                                </Row>
                        }
                    </Col>
                    <Col lg={4}>
                        <Card className={'mt-2'}>
                            <Card.Header>
                                <Card.Title>
                                    <ListUl/> Add {id.toUpperCase()} data
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Heading</Form.Label>
                                        <Form.Control name="heading" onChange={handleChange} value={add.heading} type="text" />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control name="body" onChange={handleChange} value={add.body} as="textarea" rows={3} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Info</Form.Label>
                                        <Form.Control name="info" onChange={handleChange} value={add.info} as="textarea" placeholder={'Phone no or other info'} rows={2} />
                                    </Form.Group>

                                    <InputGroup className="mb-3 mt-3">
                                        <InputGroup.Text id="basic-addon3">
                                            Sequence
                                        </InputGroup.Text>
                                        <Form.Control name='sequence' onChange={handleChange} value={add.sequence} aria-describedby="basic-addon3" />
                                    </InputGroup>
                                </Form>
                            </Card.Body>
                            <Card.Footer>
                                <Button variant={'primary'} onClick={addList}><PlusLg/> Add</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default AdminListRoute;