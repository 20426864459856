import React from 'react'
import { useNavigate } from 'react-router-dom';
import { PersonWorkspace, PhoneFill } from 'react-bootstrap-icons';
import { Badge } from 'react-bootstrap';

const PublicOfficer = ({ data, navigateUrl }) => {
    const navigator = useNavigate()
    const viewUser = () => {
        navigator(navigateUrl + data.uid)
    }

    const lists = {
        "Club Treasurer": "secondary",
        "Club President": "primary",
        "Club Secretary": "success"
    }

    return (
        <div role="button" onClick={viewUser} className="mb-2 mt-2 p-2 rounded border d-flex" style={{ height: "auto" }}>
            <img className="rounded ms-2 me-2" width={64} height={64} src={(data.picture === null) ? "/lion.png" : data.picture} alt={data.full_name} />
            <div>
                <h5>{data.name}</h5>

                <label><PhoneFill /> {data.mobile}</label><br />
                <label><Badge bg={lists[data.role]}><PersonWorkspace /> {data.role}</Badge></label>
            </div>
        </div>
    )
}

export default PublicOfficer